// Libraries
import React, { useContext, useState } from 'react';
import { format, parseISO, differenceInCalendarDays } from 'date-fns';
import { Link } from 'react-router-dom';
import { Switch } from 'design-system';
import { CheckIcon } from '@heroicons/react/16/solid';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import { NotificationsContext } from 'context';
// Components
import { Loader } from 'features/ui';
// Utils
import { UserContext } from 'context';
import {
  ProjectAttributes,
  useApi,
  IApiData,
  NotificationAttributes,
} from 'api';
import { useSnackbar } from 'hooks';
// Assets
import blueIndicator from './assets/blueIndicator.svg';
import redIndicator from './assets/redIndicator.svg';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import {
  TASK_COLUMN_IDS,
  TASK_TITLES,
} from 'features/home/customer-portal/constants';

const PRIME_MATTER_LABS = 'Prime Matter Labs';
const NOTIFICATION_TYPES = {
  CONFIRMATION: 'confirmation',
  ACTION_NEEDED: 'action_needed',
};
const NOTIFICATION_TRIGGERS = {
  BRIEF_SUBMITTED: 'brief_submitted',
  FINAL_QUOTE_ACTION_NEEDED: 'final_quote_action_needed',
  INGREDIENT_LISTING_ACTION_NEEDED: 'ingredient_listing_action_needed',
  INGREDIENT_LISTING_APPROVED: 'ingredient_listing_approved',
  INGREDIENT_LISTING_REJECTED: 'ingredient_listing_rejected',
  INGREDIENT_LISTING_UPLOADED: 'ingredient_listing_uploaded',
  PRELIM_QUOTE_ACTION_NEEDED: 'prelim_quote_action_needed',
  SAMPLE_APPROVED: 'sample_approved',
  SAMPLE_FEEDBACK_ACTION_NEEDED: 'sample_feedback_action_needed',
  SAMPLE_REJECTED: 'sample_rejected',
  TASK_STATUS_UPDATE: 'task_status_update',
  THIRD_PARTY_TESTS_ACKNOWLEDGED: 'third_party_tests_acknowledged',
  THIRD_PARTY_TESTS_ACTION_NEEDED: 'third_party_tests_action_needed',
};

export const NotificationsDashboard: React.FC = () => {
  const { userSession } = useContext(UserContext)!;
  const {
    isNotificationsLoading,
    notifications,
    setNotifications,
    setHasNewNotifications,
  } = useContext(NotificationsContext);
  const { patchCustomerNotificationsMarkAllAsViewed } = useApi();
  const { showSnackbar } = useSnackbar();

  const [showActionNeededOnly, setShowActionNeededOnly] = useState(false);

  const markAllNotificationsAsViewed = async () => {
    await patchCustomerNotificationsMarkAllAsViewed({
      urlParams: `${encodeURIComponent(
        userSession.thirdPartyId!
      )}/notifications/mark_all_notifications_as_viewed`,
      data: {},
      handleSuccess: (res) => {
        setNotifications(res.data);
        setHasNewNotifications(false);
        showSnackbar('All notifications marked as seen', 'success');
      },
    });
  };

  const determineIndicator = (
    notification: IApiData<NotificationAttributes>
  ) => {
    const {
      attributes: { notificationType, viewedAt },
    } = notification;
    return (
      <img
        className={`${
          viewedAt && notificationType !== NOTIFICATION_TYPES.ACTION_NEEDED
            ? 'opacity-0'
            : 'opacity-100'
        }`}
        src={
          notificationType === NOTIFICATION_TYPES.CONFIRMATION
            ? blueIndicator
            : redIndicator
        }
        alt="indicator"
      />
    );
  };

  const renderTaskText = (notification: IApiData<NotificationAttributes>) => {
    const {
      attributes: {
        payload: { statusUpdatedTo },
      },
      parsedIncluded: {
        source: {
          attributes: { mondayColumnId },
        },
      },
    } = notification;

    if (mondayColumnId === TASK_COLUMN_IDS.SAMPLE_SHIPPED) {
      return (
        <span className="flex">{PRIME_MATTER_LABS} shipped sample(s)</span>
      );
    }

    if (mondayColumnId === TASK_COLUMN_IDS.FEEDBACK_REVIEW) {
      return (
        <span>
          {PRIME_MATTER_LABS} set the status to
          <span className="uppercase underline mx-1">{statusUpdatedTo}</span>
          for Sample Feedback Review
        </span>
      );
    }

    if (mondayColumnId === TASK_COLUMN_IDS.TESTING) {
      return (
        <span>
          {PRIME_MATTER_LABS} set the status to {statusUpdatedTo} for{' '}
          {TASK_TITLES.TESTING}
        </span>
      );
    }

    if (mondayColumnId === TASK_COLUMN_IDS.GIVE_FEEDBACK) {
      return (
        <span>
          {PRIME_MATTER_LABS} set the status to
          <span className="uppercase underline mx-1">
            {statusUpdatedTo}
          </span>{' '}
          for Sample Feedback
        </span>
      );
    }

    return (
      <span>
        {PRIME_MATTER_LABS} set the status to
        <span className="uppercase underline mx-1">{statusUpdatedTo}</span>
        for {notification.parsedIncluded.source.attributes.name}
      </span>
    );
  };

  const determineNotificationText = (
    notification: IApiData<NotificationAttributes>
  ) => {
    const { notificationTrigger } = notification.attributes;
    const { createdBy, documentName } = notification.attributes.payload;
    const {
      project,
    }: { project: IApiData<ProjectAttributes> } = notification.parsedIncluded;

    const customer_feedback_triggers = [
      NOTIFICATION_TRIGGERS.INGREDIENT_LISTING_APPROVED,
      NOTIFICATION_TRIGGERS.INGREDIENT_LISTING_REJECTED,
    ];

    if (customer_feedback_triggers.includes(notificationTrigger)) {
      const actionText =
        notificationTrigger ===
        NOTIFICATION_TRIGGERS.INGREDIENT_LISTING_APPROVED
          ? 'approved'
          : 'submitted feedback on';

      return (
        <span>
          {`${createdBy} ${actionText} Preliminary Ingredient List `}
          <Link
            className="text-royal-50"
            to={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
              UUID_SHOW_ROUTE_STRING,
              project.id
            )}
          >
            {documentName}
          </Link>
        </span>
      );
    } else if (
      notificationTrigger === NOTIFICATION_TRIGGERS.TASK_STATUS_UPDATE
    ) {
      return renderTaskText(notification);
    } else if (
      notificationTrigger ===
      NOTIFICATION_TRIGGERS.INGREDIENT_LISTING_ACTION_NEEDED
    ) {
      return (
        <span>
          {`Action Needed: Please review Preliminary Ingredients List `}
          <Link
            className="text-royal-50"
            to={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
              UUID_SHOW_ROUTE_STRING,
              project.id
            )}
          >
            {documentName}
          </Link>
        </span>
      );
    } else if (
      notificationTrigger === NOTIFICATION_TRIGGERS.INGREDIENT_LISTING_UPLOADED
    ) {
      return (
        <span>
          {`Prime Matter Labs uploaded Preliminary Ingredient List `}
          <Link
            className="text-royal-50"
            to={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
              UUID_SHOW_ROUTE_STRING,
              project.id
            )}
          >
            {documentName}
          </Link>
        </span>
      );
    } else if (
      notificationTrigger ===
      NOTIFICATION_TRIGGERS.SAMPLE_FEEDBACK_ACTION_NEEDED
    ) {
      return <span>Action Needed: Please review and approve sample(s)</span>;
    } else if (
      notificationTrigger === NOTIFICATION_TRIGGERS.SAMPLE_REJECTED ||
      notificationTrigger === NOTIFICATION_TRIGGERS.SAMPLE_APPROVED
    ) {
      const actionText =
        notificationTrigger === NOTIFICATION_TRIGGERS.SAMPLE_REJECTED
          ? 'submitted feedback on'
          : 'approved';

      return (
        <span>
          {`${
            notification.parsedIncluded.recipient?.attributes.fullName
          } has ${actionText} sample formula ${
            (notification.attributes.payload as any).formulaNumber || ''
          }`}
        </span>
      );
    } else if (
      notificationTrigger ===
      NOTIFICATION_TRIGGERS.THIRD_PARTY_TESTS_ACTION_NEEDED
    ) {
      return (
        <span>
          Action Needed: Please complete 3rd party testing acknowledgement form
        </span>
      );
    } else if (
      notificationTrigger ===
      NOTIFICATION_TRIGGERS.THIRD_PARTY_TESTS_ACKNOWLEDGED
    ) {
      return (
        <span>3rd party testing acknowledgement form has been completed</span>
      );
    } else if (notificationTrigger === NOTIFICATION_TRIGGERS.BRIEF_SUBMITTED) {
      return (
        <span>{`${notification.parsedIncluded.recipient?.attributes.fullName} submitted a brief`}</span>
      );
    } else if (
      notificationTrigger ===
        NOTIFICATION_TRIGGERS.PRELIM_QUOTE_ACTION_NEEDED ||
      notificationTrigger === NOTIFICATION_TRIGGERS.FINAL_QUOTE_ACTION_NEEDED
    ) {
      const quoteType =
        notificationTrigger === NOTIFICATION_TRIGGERS.PRELIM_QUOTE_ACTION_NEEDED
          ? 'Preliminary'
          : 'Final';

      return (
        <span>Action Needed: Please review and approve {quoteType} Quote</span>
      );
    }
  };

  const renderDaysAgoMessage = (createdAt: string) => {
    const numDays = differenceInCalendarDays(new Date(), parseISO(createdAt));
    if (numDays === 0) return 'Today';
    return `${numDays} day${numDays > 1 ? 's' : ''} ago`;
  };

  const renderNotifications = (
    notificationsToRender: IApiData<NotificationAttributes>[]
  ) => {
    const sortedNotifications = [...notificationsToRender].sort((a, b) => {
      return (
        parseISO(b.attributes.createdAt).getTime() -
        parseISO(a.attributes.createdAt).getTime()
      );
    });

    const filteredNotifications = sortedNotifications.filter((notification) => {
      const { payload, notificationType } = notification.attributes;
      const actionNeeded =
        notificationType === NOTIFICATION_TYPES.ACTION_NEEDED;
      return (
        (!showActionNeededOnly || actionNeeded) &&
        !(actionNeeded && payload?.actionCompleted)
      );
    });

    if (filteredNotifications.length === 0) {
      return renderEmptyState();
    }

    return filteredNotifications.map((notification) => {
      const { notificationType, createdAt } = notification.attributes;
      const { project } = notification.parsedIncluded;
      const actionNeeded =
        notificationType === NOTIFICATION_TYPES.ACTION_NEEDED;
      return (
        <Link
          to={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
            UUID_SHOW_ROUTE_STRING,
            project.id
          )}
        >
          <div
            key={notification.id}
            className={`flex sm:flex-row flex-col cursor-pointer font-inter text-sm sm:items-center mt-4 p-3 rounded-[4px] hover:bg-blue-95 ${
              actionNeeded ? 'border border-grey-90' : ''
            }`}
          >
            <div className="flex items-center">
              <div className="shrink-0 mr-6">
                {determineIndicator(notification)}
              </div>
              <div className="flex flex-col">
                <div className="text-left">
                  {determineNotificationText(notification)}
                </div>
                <div className="flex gap-2">
                  <span className="text-royal-50 cursor-pointer">
                    {project.attributes.name}
                  </span>

                  <span className="text-grey-50">
                    {actionNeeded
                      ? renderDaysAgoMessage(createdAt)
                      : format(parseISO(createdAt), 'MMM dd, yyyy h:mma')}
                  </span>
                </div>
              </div>
            </div>
            {actionNeeded && (
              <div className="flex w-[90%] mt-4 sm:mt-0 sm:w-auto text-xs ml-[30px] sm:ml-auto rounded-[4px] bg-royal-50 hover:bg-royal-40 active:bg-royal-30 p-1 text-grey-90">
                <Link
                  to={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
                    UUID_SHOW_ROUTE_STRING,
                    project.id
                  )}
                  rel="noopener noreferrer"
                >
                  <button className="flex items-center whitespace-nowrap">
                    Go to project
                    <ArrowRightIcon className="ml-1" height="12" width="12" />
                  </button>
                </Link>
              </div>
            )}
          </div>
        </Link>
      );
    });
  };

  const renderEmptyState = () => {
    return (
      <div className="h-[200px] mt-4 flex justify-center items-center font-inter text-grey-60 text-sm border border-grey-90 bg-grey-95 rounded-[4px]">
        You're all caught up - no actions needed at this time.
      </div>
    );
  };

  return (
    <div
      id="pageWrapper"
      className="max-w-[75%] grid grid-cols-12 mx-auto font-inter text-grey-20 sm:pb-[130px] pb-[160px]"
    >
      <div
        id="contentWrapper"
        className="flex flex-col pr-3 col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 mt-[60px]"
      >
        <div className="flex justify-end items-center mb-6">
          <Switch
            isChecked={showActionNeededOnly}
            handleChange={() => setShowActionNeededOnly(!showActionNeededOnly)}
          />
          <span className="ml-[6px] text-xs">Action Needed only</span>
          <button
            onClick={() => markAllNotificationsAsViewed()}
            className="flex text-xs ml-8 p-1 rounded bg-grey-90 hover:bg-royal-90 active:bg-royal-80"
          >
            <CheckIcon className="h-4 w-4" />
            Mark all as seen
          </button>
        </div>
        <div className="pb-[5px] flex text-xs font-mono text-grey-50 font-[500] border-b border-b-grey-90">
          ACTIVITY LOG
        </div>
        {isNotificationsLoading ? (
          <Loader />
        ) : (
          renderNotifications(notifications)
        )}
      </div>
    </div>
  );
};
