import { PERMISSIONS } from 'features/rbac/auth.constants';
const {
  VISIT_AUTHORIZED_APPLICATION,
  VISIT_EMPLOYEE_PAGE,
  VISIT_RTL_FORMULA_CATALOGUE,
} = PERMISSIONS;

const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:3000';
    case 'staging':
      return 'https://r-and-d-client-staging.netlify.app';
    default:
      return 'http://app.primematterlabs.com';
  }
};

export const BASE_URL = getBaseUrl();

export const ROUTES = {
  SIGNIN: {
    text: 'Signin',
    route: '/signin',
  },
  HOME: {
    text: 'Home',
    route: '/',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  CREATE_BRIEF: {
    text: 'Build Your Project',
    route: '/custom-brief',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  PAYMENT_CONFIRMATION_INTERSTITIAL: {
    text: 'Payment Confirmation',
    route: 'rtl-order-payment-confirmation',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  CREATE_INTERNAL_BRIEF: {
    text: 'Create an Internal Brief',
    route: '/internal-brief/new',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  //Cannot change the following URI due to customer
  //emails sent with these links
  SHOW_CUSTOMER_BRIEF_VIEW: {
    text: 'Project',
    route: '/project/:uuid',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  CREATE_FORMULA: {
    text: 'Create a Formula',
    route: '/formulas/new',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  SHOW_FORMULA: {
    text: 'Formula',
    route: '/formulas/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  RAW_MATERIALS: {
    text: 'Raw Materials Dictionary',
    route: '/raw-materials/search',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  CREATE_RAW_MATERIAL: {
    text: 'Add a Raw Material',
    route: '/raw-materials/new',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  SHOW_RAW_MATERIAL: {
    text: 'Raw Materials',
    route: '/raw-materials/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  INCIS: {
    text: 'INCI Glossary',
    route: '/incis',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  CREATE_INCI: {
    text: 'Add an INCI',
    route: '/incis/new',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  SHOW_INCI: {
    text: 'INCIs',
    route: '/incis/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  PROFILE: {
    text: 'Profile',
    route: '/profile',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  BRIEF_SUBMISSION_SUCCESS: {
    route: '/submission_success',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  CREATE_PROJECT: {
    text: 'Build a Project',
    route: '/projects/new',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  SHOW_PROJECT: {
    text: 'Project',
    route: '/projects/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  SHOW_PROJECT_FILE: {
    route: '/projects/:projectUuid/files/:fileUuid',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  SHOW_CUSTOMER_PROJECT_V2: {
    text: 'Project',
    route: '/your-projects/:uuid',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  RTL_FORMULA_CATALOGUE: {
    text: 'RTL Formula Catalogue',
    route: '/rtl-formula-catalogue',
    visitPermission: VISIT_RTL_FORMULA_CATALOGUE,
  },
  CREATE_RTL_CATALOGUE_ENTRY: {
    text: 'Create New RTL Catalogue Entry',
    route: '/rtl-formula-catalogue/new',
    visitPermission: VISIT_RTL_FORMULA_CATALOGUE,
  },
  CUSTOMER_NOTIFICATIONS_DASHBOARD: {
    text: 'Notifications Dashboard',
    route: '/notifications',
    visitPermission: VISIT_AUTHORIZED_APPLICATION,
  },
  EMPLOYEE_VIEW_CUSTOMER_PROJECT: {
    text: 'Project',
    route: '/employee-view-project-tracker/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  EMPLOYEE_VIEW_CUSTOMER_DASHBOARD: {
    text: 'Project',
    route: '/employee-view-customer-projects/:uuid',
    visitPermission: VISIT_EMPLOYEE_PAGE,
  },
  TERMS_OF_USE: {
    text: 'Terms of Use',
    route: '/terms-of-use',
  },
  PRIVACY_POLICY: {
    text: 'Privacy Policy',
    route: '/privacy-policy',
  },
  REGULATORY_INFO: {
    text: 'Regulatory Information',
    route: '/regulatory-info',
  },
  ONBOARDING: {
    route: '/onboarding',
  },
  VERIFY_EMAIL: {
    route: '/verify-email',
  },
  NOT_FOUND_404: {
    route: '/not-found',
  },
  FORBIDDEN_403: {
    route: '/forbidden',
  },
};

// ROUTE STRINGS
export const UUID_SHOW_ROUTE_STRING = ':uuid';

// NAVBAR
export const NAVBAR_HEIGHT = 64;
