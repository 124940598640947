// Libraries
import React, { useState } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/16/solid';
// Assets
import ReadyToLaunchFullLogo from 'img/ready-to-launch-full-logo.svg';

export const RtlFormulaCatalogueTopNav = () => {
  const [numberOfItemsInCart] = useState(0);
  return (
    <div className="col-span-12 sticky top-0 bg-white z-10">
      <div className=" py-6 flex w-full justify-between items-center">
        <div className="flex gap-2 items-center">
          <img
            width="20"
            height="20"
            src={ReadyToLaunchFullLogo}
            alt="Ready to Launch logo"
          />
          <span className="uppercase font-agipo">Ready to launch</span>
        </div>
        <div className="flex items-center gap-2 cursor-pointer">
          <ShoppingCartIcon className="h-4 w-4" />
          <div className="font-mono uppercase text-xs">
            <span>Samples cart</span>
            <span className={`${numberOfItemsInCart > 0 && 'text-royal-50'}`}>
              {' '}
              {`(${numberOfItemsInCart})`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
