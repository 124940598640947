// Library
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  Basics,
  Closure,
  ProductBasics,
  Spf,
  Tint,
  FormulaSpecifics,
  CountryCompliance,
  PrimaryComponent,
  Material,
  SiliconeFree,
} from '../../form-modules';
import { PrimeTip } from '../prime-tip.component';
import { Divider, Typography } from 'design-system';
import { RtlWrapper } from '../rtl-wrapper.component';
// Utils
import { CustomerBriefFormValues } from '../../internal-brief/form.config';
// Constants
import { PRODUCT_CATEGORIES } from '../../brief.constants';

const BASICS_TIP = [
  'Have multiple products in mind? No problem. Start with one, then a Prime representative will contact you to learn about additional needs. You’ll also have an option to leave us notes on the last page of this form.',
];

interface FormulaProps {
  allRtlModulesHaveValues: boolean;
  projectIsRtl: boolean;
}

export const Formula: React.FC<FormulaProps> = ({
  allRtlModulesHaveValues,
  projectIsRtl,
}) => {
  const { values } = useFormikContext<CustomerBriefFormValues>();

  window.history.pushState(null, '', '/custom-brief/#formula');

  return (
    <div className="flex flex-col pt-[52px]">
      <div className="flex flex-col sm:grid sm:grid-cols-9 gap-x-12">
        <div className="col-span-9 flex flex-col">
          <Typography variant="h2" weight="black">
            Formula
          </Typography>
          <Typography variant="h4" font="inter" color="secondary">
            What would you like to make?
          </Typography>
        </div>
        <div className="flex flex-col col-span-6 mt-9 row-span-1">
          <Basics />
          <Divider className="mt-[10px]" />
        </div>
        <div className="sm:col-span-3 col-span-6 mb-12 sm:mb-0 row-span-2">
          <PrimeTip contents={BASICS_TIP} />
        </div>
        <ProductBasics projectIsRtl={projectIsRtl} />
        {values.productType.value && (
          <div className="flex flex-col gap-9 col-span-6 mt-12">
            <Divider />
            <FormulaSpecifics projectIsRtl={projectIsRtl} />
          </div>
        )}
        <RtlWrapper
          shouldRender={Boolean(
            values.productCategory.value === PRODUCT_CATEGORIES.SUN_CARE &&
              values.structure
          )}
        >
          <>
            <div className="col-span-6 mt-12 mb-9">
              <Divider />
            </div>
            <div className="col-span-6">
              <Spf projectIsRtl={projectIsRtl} />
            </div>
          </>
        </RtlWrapper>
        <RtlWrapper shouldRender={Boolean(values.spf)}>
          <>
            <div className="col-span-6 mt-12 mb-9">
              <Divider />
            </div>
            <div className="col-span-6">
              <Tint projectIsRtl={projectIsRtl} />
            </div>
          </>
        </RtlWrapper>
        <RtlWrapper shouldRender={Boolean(values.tint)}>
          <>
            <div className="col-span-6 mt-12 mb-9">
              <Divider />
            </div>
            <div className="col-span-6">
              <SiliconeFree projectIsRtl={projectIsRtl} />
            </div>
          </>
        </RtlWrapper>
        <RtlWrapper shouldRender={allRtlModulesHaveValues}>
          <>
            <div className="col-span-6 mt-12 mb-9">
              <Divider />
            </div>
            <div className="col-span-6">
              <CountryCompliance projectIsRtl={projectIsRtl} />
            </div>
            <div className="col-span-6 mt-12 mb-9">
              <Divider />
            </div>
            <div className="flex flex-col gap-6 col-span-6">
              <PrimaryComponent />
              <Material />
              <Closure />
            </div>
          </>
        </RtlWrapper>
      </div>
    </div>
  );
};
