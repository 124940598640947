// Libraries
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DocumentPlusIcon } from '@heroicons/react/16/solid';
// Components
import {
  Autocomplete,
  BottomBar,
  Button,
  CollapsibleSection,
  Modal,
  Page,
  Select,
  Switch,
  TextArea,
  TextField,
} from 'design-system';
import { RtlFormulaCatalogueTopNav } from './rtl-formula-catalogue-top-nav';
// Utils
import { useApi } from 'api';
// Constants
import { RTL_CATALOGUE_IMAGES } from './constants';

interface FormItem {
  title: string;
  items: React.ReactNode | React.ReactNode[];
}

interface FormItems {
  [key: string]: FormItem;
}

export const CreateNewCatalogueEntry = () => {
  const { state } = useLocation();
  const { productTypeLabel, productTypeValue } = state || {};
  const { postRtlCatalogueEntry, searchFormulas } = useApi();

  const [selectedFormulaImage, setSelectedFormulaImage] = useState<
    null | number
  >(null);
  const [showSaveAsDraftModal, setShowSaveAsDraftModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      format: { label: '', value: '' },
      formulaName: '',
      imageId: '',
      brand: '',
      rmDocs: '',
      moq: '',
      spf: '',
      hydrous: '',
      ultaClean: false,
      sephoraClean: false,
      targetClean: false,
      cyclicSilicneFree: false,
      containsNano: false,
      fragranceFree: false,
      componentSize: '',
      lot: '',
      componentCode: '',
      materials: '',
      additionalComponents: '',
      validatedBatchSize: '',
      spfDetermination: '',
      fdaSpfDetermination: '',
      fdaBroadSpectrum: false,
      waterResistant: false,
      iso24443: false,
      iso24444: false,
      pet: '',
      ript: false,
      stabilityGlass: '',
      stabilityRDComponents: '',
      originalProductName: '',
    },
    validationSchema: Yup.object({
      formulaName: Yup.string().required('Please enter a formula name'),
    }),
    onSubmit: (values) => {
      postRtlCatalogueEntry({
        data: {
          rtlCatalogueEntry: {
            imageId: values.imageId,
            productType: productTypeValue,
            displayName: values.formulaName,
          },
        },
        handleSuccess: (response) => {
          console.log(response);
        },
      });
    },
  });

  const renderFormulaImages = () => {
    return (
      <div className="flex flex-wrap gap-[18px] mt-4 max-w-[406px]">
        {RTL_CATALOGUE_IMAGES.map((image, index) => (
          <img
            alt={`formula ${index}`}
            className={`h-[83px] w-[83px] box-border rounded cursor-pointer border-4  ${
              selectedFormulaImage === index
                ? 'border-royal-50'
                : 'border-white'
            }`}
            key={index}
            onClick={() => setSelectedFormulaImage(index)}
            src={image.src}
          />
        ))}
      </div>
    );
  };

  const renderInfoRow = (label: string, info: any) => {
    return (
      <div className="flex justify-between py-4 border-b border-grey-90">
        <span className="text-grey-50">{label}</span>
        {info}
      </div>
    );
  };

  const renderInputRow = (label: string, input: any) => {
    return (
      <div className="flex gap-6 items-center py-[6px] border-b border-grey-90 last:border-b-0">
        <span className="w-1/2 text-grey-50">{label}</span>
        <div className="w-1/2">{input}</div>
      </div>
    );
  };

  const renderFormSection = (section: any) => {
    return (
      <CollapsibleSection title={section.title}>
        <div className="flex flex-col px-4 border border-grey-90">
          {section.items.map((item: any) => item)}
        </div>
      </CollapsibleSection>
    );
  };

  const renderSwitchRow = (label: string, input: any) => {
    return (
      <div className="flex justify-between items-center py-3 border-b border-grey-90 last:border-b-0">
        <span className="w-1/2 text-grey-50">{label}</span>
        {input}
      </div>
    );
  };

  const formItems: FormItems = {
    formulaDetails: {
      title: 'Formula Details',
      items: [
        renderInputRow(
          'Formula Number',
          <Autocomplete
            onChange={(option: Option) =>
              formik.setFieldValue('formulaNumber', option)
            }
            responseParser={(response) =>
              response?.results.map((result: any) => ({
                label: result._source.external_id,
                value: result._source.uuid,
              }))
            }
            search={searchFormulas}
            urlParams={'&filters=external_id'}
          />
        ),
        renderInfoRow('Product Type', <span>{productTypeLabel || ''}</span>),
        renderInfoRow(
          'BF# / Formula Number',
          <Link className="text-royal-50" to={`/formulas/${''}`}>
            {'12345'}
          </Link>
        ),
        renderInputRow(
          'Format',
          <Select
            placeholder="Select a format"
            options={[{ label: '1', value: '1' }]}
          />
        ),
        renderInputRow('Brand/Source', <TextField />),
        renderInputRow('RM Docs', <TextField />),
        renderInputRow('MOQ', <TextField />),
      ],
    },
    sunCareSpecifics: {
      title: 'Sun Care Specifics',
      items: [
        renderInputRow('SPF Type', <TextField />),
        renderInputRow(
          'Hydrous (water), Anhydrous or Emulsion (both)',
          <TextField />
        ),
      ],
    },
    claims: {
      title: 'Claims',
      items: [
        renderSwitchRow(
          'Ulta Clean',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'Sephora Clean',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'Target Clean',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'Cycle Silicone Free',
          <Switch isChecked={false} handleChange={() => null} />
        ),
      ],
    },
    fragrance: {
      title: 'Fragrance',
      items: [
        renderSwitchRow(
          'Contains Nano',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'Fragrance Free',
          <Switch isChecked={false} handleChange={() => null} />
        ),
      ],
    },
    testing: {
      title: 'Testing',
      items: [
        renderInputRow('Component Size', <TextField />),
        renderInputRow('Lot #', <TextField />),
        renderInputRow('Component Code', <TextField />),
        renderInputRow('Materials', <TextField />),
        renderInputRow(
          'Additional Components Tested in Annual/Room Temp. Studies (Production)',
          <TextField />
        ),
        renderInputRow('Validated Batch Size', <TextField />),
        renderInputRow('SPF Determination (*theoretical)', <TextField />),
        renderInputRow('FDA SPF Determination', <TextField />),
        renderSwitchRow(
          'FDA Broad Spectrum',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'Water Resistant',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'ISO 24443',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderSwitchRow(
          'ISO 24444',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderInputRow('PET', <TextField height="h-11" />),
        renderSwitchRow(
          'RIPT',
          <Switch isChecked={false} handleChange={() => null} />
        ),
        renderInputRow('Stability Glass', <TextField height="h-11" />),
        renderInputRow(
          'Stability R&D in Components/Compatibility',
          <TextField height="h-11" />
        ),
        renderInputRow('Original Product Name', <TextField height="h-11" />),
      ],
    },
  };

  return (
    <Page>
      <RtlFormulaCatalogueTopNav />
      <div className="col-span-12 flex justify-between my-24 max-w-[1440px]">
        <div className="flex flex-col">
          <img
            src={RTL_CATALOGUE_IMAGES[selectedFormulaImage || 0].src}
            alt="selected formula"
            className="h-[406px] w-[406px] rounded"
          />
          <span className="text-grey-50 mt-6">Select a featured image:</span>
          {renderFormulaImages()}
        </div>
        <div className="flex flex-col gap-9 grow px-6">
          <TextField
            error={formik.errors.formulaName}
            label="Formula Name (Required)"
            width={'w-[357px]'}
            placeholder="Enter a formula name"
            {...formik.getFieldProps('formulaName')}
          />
          <TextArea
            label="Formula Description"
            rows={6}
            placeholder="Describe this formula"
          />
          <TextField
            label="Price per ounce (Required)"
            placeholder="00.00"
            leadingText="$"
            trailingText="per ounce"
            helperText="Enter the closest current estimate."
          />
          {Object.keys(formItems).map((key) =>
            renderFormSection(formItems[key])
          )}
        </div>
      </div>
      {showSaveAsDraftModal && (
        <Modal handleClose={() => setShowSaveAsDraftModal(false)}>
          <div className="flex flex-col">
            <div className="flex flex-col gap-3 p-6">
              <span className="font-semibold">Save as draft?</span>
              <span>
                Drafts are visible to PML employees, but cannot be seen by
                customers
              </span>
            </div>
            <div className="flex gap-4 p-4 border-t border-grey-90 justify-end">
              <Button
                type="secondary"
                text="Nevermind"
                onClick={() => setShowSaveAsDraftModal(false)}
              />
              <Button text="Save draft" />
            </div>
          </div>
        </Modal>
      )}
      <BottomBar
        additionalStyles={'-ml-[112px]'}
        buttons={[
          <Button text="Cancel" type="secondary" />,
          <Button
            leadingIcon={<DocumentPlusIcon className="text-royal-50" />}
            onClick={() => setShowSaveAsDraftModal(true)}
            text="Save Draft"
            type="secondary"
          />,
          <Button text="Publish" onClick={formik.handleSubmit} />,
        ]}
      />
    </Page>
  );
};
