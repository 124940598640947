// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BarsArrowDownIcon,
  ChevronDownIcon,
  ClockIcon,
  EyeDropperIcon,
  HandRaisedIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  SparklesIcon,
  SunIcon,
  WrenchIcon,
} from '@heroicons/react/16/solid';
import { Bars4Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
// Components
import {
  Button,
  Chip,
  Menu,
  Page,
  Pagination,
  Select,
  TextField,
} from 'design-system';
// Utils
import { USDollar } from 'features/utils';
import { MobileContext } from 'context';
import { useApi } from 'api';
// Assets
import { RtlFormulaCatalogueTopNav } from './rtl-formula-catalogue-top-nav';
// Constants
import { RTL_CATALOGUE_IMAGES, RLT_CATALOGUE_STATUSES } from './constants';

const sortDropdownOptions = [
  {
    label: 'Alphabetical',
    value: 'alphabetical',
    leadingIcon: <BarsArrowDownIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Newest',
    value: 'newest',
    leadingIcon: <ClockIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Last Modified',
    value: 'lastModified',
    leadingIcon: <WrenchIcon className="h-5 w-5 text-grey-50" />,
  },
];

const productTypes = [
  {
    label: 'Sun Care',
    value: 'sunCare',
    leadingIcon: <SunIcon className="h-5 w-5 text-peach-50" />,
  },
  {
    label: 'Skin Care',
    value: 'skinCare',
    leadingIcon: <EyeDropperIcon className="h-5 w-5 text-royal-50" />,
  },
  {
    label: 'Hair Care',
    value: 'hairCare',
    leadingIcon: <SparklesIcon className="h-5 w-5 text-teal-50" />,
  },
  {
    label: 'Body Care',
    value: 'bodyCare',
    leadingIcon: <HandRaisedIcon className="h-5 w-5 text-yellow-50" />,
  },
];

const formulaTableHeaders = [
  'MOQ',
  'Format',
  'Component',
  'Sample Size',
  'CPU (per oz)',
];

export const RtlFormulaCatalogue = () => {
  const [primarySortOption, setPrimarySortOption] = useState(
    sortDropdownOptions[0]
  );
  const [rtlCatalogueEntries, setRtlCatalogueEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFilterChips, setSelectedFilterChips] = useState(['']);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [showProductTypeDropDown, setShowProductTypeDropDown] = useState(false);

  const navigate = useNavigate();
  const { isMobile } = useContext(MobileContext);
  const { searchRtlCatalogueEntries } = useApi();

  useEffect(() => {
    searchRtlCatalogueEntries({
      query: encodeURIComponent(searchQuery),
      urlParams: `&page=1&perPage=15`,
      handleSuccess: (data) => {
        setRtlCatalogueEntries(data.results);
      },
    });
  }, [searchQuery, searchRtlCatalogueEntries]);

  // Function to handle the click event on the product type dropdown
  const handleProductTypeClick = (option: Option) => {
    navigate('/rtl-formula-catalogue/new', {
      state: { productTypeLabel: option.label, productTypeValue: option.value },
    });
  };

  // Function that handles the rendering of the catalogue entry image in the grid view
  const renderGridViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-[191px] w-full sm:w-[233px] bg-grey-70 rounded">
          <span>No Image Selected</span>
        </div>
      );
    }
    return (
      <img
        src={
          RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
            ?.src || 'http://www.google.com'
        }
        alt="entry"
        className="h-[191px] w-full object-cover sm:w-[233px] rounded"
      />
    );
  };

  // Function that handles the rendering of the catalogue entry image in the list view
  const renderListViewCatalogueEntryImage = (entry: any) => {
    if (!entry.image_id) {
      return (
        <div className="flex items-center justify-center h-6 w-6 bg-grey-70 rounded" />
      );
    }
    return (
      <img
        src={
          RTL_CATALOGUE_IMAGES.find((image) => image.id === entry.image_id)
            ?.src || 'http://www.google.com'
        }
        alt="entry"
        className="h-6 w-6 object-cover sm:w-[191px] rounded"
      />
    );
  };

  // Function that handles the rendering of table headers in the list view
  const renderTableHeaders = () => {
    return formulaTableHeaders.map((headerName) => (
      <div className="flex items-center gap-1 cursor-pointer">
        <span>{headerName}</span>
        <ChevronDownIcon className="h-4 w-4 text-royal-50" />
      </div>
    ));
  };

  // Function that handles the rendering of catalogue entry table rows in the list view
  const renderCatalogueEntryRows = () => {
    if (rtlCatalogueEntries.length === 0) return;

    return rtlCatalogueEntries.map((entry: any, index: number) => {
      return isMobile ? (
        <div
          key={`${entry._source.uuid}-${index}`}
          className="flex flex-col py-3 gap-6"
        >
          <div className="flex items-center gap-4">
            {renderListViewCatalogueEntryImage(entry._source)}
            <span className="text-black font-semibold w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {entry._source.display_name}
            </span>
          </div>
          <div className="flex flex-col gap-2 text-grey-50">
            <span>
              {new Intl.NumberFormat('en-US').format(entry?._source.moq)}
            </span>
            <span>{entry?._source.format}</span>
            <span>{entry?._source.component}</span>
            <span>{entry?._source.sampleSize}</span>
            <span className="text-black">
              {USDollar.format(entry?._source.price || 0)}
            </span>
          </div>
          <Button
            text="Add to Cart"
            type="primary"
            size="small"
            leadingIcon={<PlusIcon className="h-4 w-4" />}
          />
        </div>
      ) : (
        <div className="grid items-center grid-cols-rtlFormulaCatalogueListViewTableHeader py-3 gap-x-[56px] border-b border-grey-90 text-grey-50">
          {renderListViewCatalogueEntryImage(entry._source)}
          <span className="-ml-10 text-black font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
            {entry?._source.display_name || 'TBD'}
          </span>
          <span>
            {new Intl.NumberFormat('en-US').format(entry?._source.moq)}
          </span>
          <span>{entry?._source.format}</span>
          <span>{entry?._source.component}</span>
          <span>{entry?._source.sampleSize}</span>
          <span>{USDollar.format(entry?._source.price || 0)}</span>
          <div>
            <Button
              text="Add to Cart"
              type="primary"
              size="small"
              leadingIcon={<PlusIcon className="h-4 w-4" />}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <Page>
      <RtlFormulaCatalogueTopNav />
      <div className="max-w-[1440px] col-span-2 sm:col-span-12 flex flex-col sm:flex-row sm:justify-between pt-52">
        <div className="sm:w-1/2 w-full flex flex-col">
          <span className="font-agipo text-[48px] font-bold">
            RTL Formula Library
          </span>
          <span className="font-inter text-grey-50">
            This is PML’s library of off-the-shelf formulas. These formulas have
            a faster production time and lower costs to our clients because
            they’ve been pre-tested. Clients can make minor adjustments to
            fragrance and ingredients. Browse formulas, then add them to your
            cart to send samples to clients.
          </span>
        </div>
        <div className="self-start relative mt-16 sm:mt-0 w-full sm:w-auto">
          <Button
            leadingIcon={<PlusIcon />}
            onClick={() => setShowProductTypeDropDown(true)}
            size="large"
            text="New RTL Formula"
            type="primary"
            width="w-full"
          />
          {showProductTypeDropDown && (
            <Menu
              options={productTypes}
              onChange={(option: Option) => handleProductTypeClick(option)}
              onClose={() => setShowProductTypeDropDown(false)}
            />
          )}
        </div>
      </div>
      <div className="max-w-[1440px] sticky top-[72px] col-span-12 py-16 bg-white z-10">
        <div className="bg-white flex flex-col gap-6 sm:gap-0 sm:flex-row justify-between items-center">
          <div className="w-full sm:w-1/2">
            <TextField
              placeholder="Search"
              trailingIcon={
                <MagnifyingGlassIcon className="text-grey-50 h-5 w-5" />
              }
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              height="h-11"
            />
          </div>
          <div className="w-full sm:w-auto flex items-center gap-6">
            <div className="flex gap-3 text-grey-50">
              <Squares2X2Icon
                className={`h-6 w-6 cursor-pointer ${
                  viewMode === 'grid' && 'text-royal-50'
                }`}
                onClick={() => setViewMode('grid')}
              />
              <Bars4Icon
                className={`h-6 w-6 cursor-pointer ${
                  viewMode === 'list' && 'text-royal-50'
                }`}
                onClick={() => setViewMode('list')}
              />
            </div>
            <Select
              leadingIcon={primarySortOption.leadingIcon}
              options={sortDropdownOptions}
              value={primarySortOption}
              onChange={setPrimarySortOption}
              width="w-full"
            />
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] pb-6 flex flex-wrap sticky top-[312px] sm:top-[244px] col-span-12 justify-center gap-4 bg-white">
        {productTypes.map((productType) => {
          return (
            <Chip
              key={productType.value}
              bgColor="bg-grey-95"
              closeIconColor="text-royal-80"
              label={productType.label}
              onClick={() => {
                setSelectedFilterChips((prev) => {
                  if (prev.includes(productType.value)) {
                    return [...prev];
                  }
                  return [...prev, productType.value];
                });
              }}
              onCloseIconClick={(event) => {
                event.stopPropagation();
                setSelectedFilterChips((prev) => {
                  return prev.filter((value) => value !== productType.value);
                });
              }}
              textColor="text-grey-40"
              selected={selectedFilterChips.includes(productType.value)}
              selectedBgColor="bg-royal-95"
              selectedTextColor="text-royal-40"
              value={productType.value}
            />
          );
        })}
      </div>
      {viewMode === 'grid' ? (
        <div className="max-w-[1440px] flex justify-center sm:justify-start col-span-2 sm:col-span-12 flex-wrap gap-x-6 gap-y-9 mb-6">
          {rtlCatalogueEntries?.length &&
            rtlCatalogueEntries.map((entry: any, index) => {
              return (
                <div
                  key={index}
                  className="flex w-full sm:w-auto flex-col font-inter text-sm cursor-pointer"
                >
                  {renderGridViewCatalogueEntryImage(entry._source)}
                  <div className="flex justify-between items-center my-[6px] sm:max-w-[233px]">
                    <div className="flex w-2/3">
                      {entry._source.status ===
                        RLT_CATALOGUE_STATUSES.DRAFT && (
                        <Chip
                          additionalClasses="mr-[2px]"
                          bgColor="bg-red-95"
                          borderColor="border-red-80"
                          label="Draft"
                          size="small"
                          textColor="text-red-40"
                          value="Draft"
                        />
                      )}
                      <span className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {entry._source.display_name || 'TBD'}
                      </span>
                    </div>
                    <span className="">
                      {USDollar.format(entry._source.price || 0)}
                    </span>
                  </div>
                  <span className="text-grey-50">{entry._source.format}</span>
                  <span className="text-grey-50">
                    {entry._source.component}
                  </span>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="max-w-[1440px] col-span-12 font-inter text-sm">
          <div className="hidden sm:grid grid-cols-rtlFormulaCatalogueListViewTableHeader gap-x-[56px] py-3 border-b border-grey-90">
            <div id="imageColumnPlaceholder" className="py-3"></div>
            <div className="flex items-center gap-1 cursor-pointer -ml-10">
              <span>Formula Name</span>
              <ChevronDownIcon className="h-4 w-4 text-royal-50" />
            </div>
            {renderTableHeaders()}
          </div>
          <div id="addToCartButtonPlaceholder"></div>
          {renderCatalogueEntryRows()}
        </div>
      )}
      <div className="max-w-[1440px] col-span-12">
        <Pagination
          numResultsStart={'1'}
          numResultsEnd={'10'}
          totalResults={'1000'}
          totalPages={20}
        />
      </div>
    </Page>
  );
};
