import image01 from 'img/rtl_catalogue/image_01.png';
import image02 from 'img/rtl_catalogue/image_02.png';
import image03 from 'img/rtl_catalogue/image_03.png';
import image04 from 'img/rtl_catalogue/image_04.png';
import image05 from 'img/rtl_catalogue/image_05.png';
import image06 from 'img/rtl_catalogue/image_06.png';
import image07 from 'img/rtl_catalogue/image_07.png';
import image08 from 'img/rtl_catalogue/image_08.png';
import image09 from 'img/rtl_catalogue/image_09.png';
import image10 from 'img/rtl_catalogue/image_10.png';
import image11 from 'img/rtl_catalogue/image_11.png';
import image12 from 'img/rtl_catalogue/image_12.png';
import image13 from 'img/rtl_catalogue/image_13.png';
import image14 from 'img/rtl_catalogue/image_14.png';
import image15 from 'img/rtl_catalogue/image_15.png';

export const RTL_CATALOGUE_IMAGES = [
  { id: '01', src: image01 },
  { id: '02', src: image02 },
  { id: '03', src: image03 },
  { id: '04', src: image04 },
  { id: '05', src: image05 },
  { id: '06', src: image06 },
  { id: '07', src: image07 },
  { id: '08', src: image08 },
  { id: '09', src: image09 },
  { id: '10', src: image10 },
  { id: '11', src: image11 },
  { id: '12', src: image12 },
  { id: '13', src: image13 },
  { id: '14', src: image14 },
  { id: '15', src: image15 },
];

export enum RLT_CATALOGUE_STATUSES {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}
