// Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  ArrowRightIcon,
  ArrowDownTrayIcon,
  CheckIcon,
} from '@heroicons/react/16/solid';
import * as amplitude from '@amplitude/analytics-browser';
// Components
import { Button, IncrementerDecrementer, Typography } from 'design-system';
// Utils
import { useApi as useApiV1 } from 'hooks';
import { useApi } from 'api';
import { ExtendedFormulaAttributes } from '../types';
import { useMobile } from 'hooks';
import { UserContext } from 'context';
// Constants
import * as Constants from 'features/constants';

const COST_PER_SAMPLE = 100;
// Temp hardcoded values for report generation
const formatType = 'pdf';
const reportTypes = {
  cosmetics: {
    type: 'USCosmetics',
    inputs: {
      params: 'roundBy',
      value: '0',
    },
  },
  drugs: {
    type: 'USDrugs',
    inputs: {
      params: 'displayLessThanOnePercentLine',
      value: false,
    },
  },
};

type ReportInputs = {
  params: string;
  value: any;
};

interface RtlConfirmationProps {
  projectUuid: string | null;
  rtlFormulaUuid: string | null;
}

export const RtlConfirmation: React.FC<RtlConfirmationProps> = ({
  projectUuid,
  rtlFormulaUuid,
}) => {
  const request = useApiV1();
  const { getFormula, postProjectOrder } = useApi();
  const { isMobile } = useMobile();
  const { userSession } = useContext(UserContext)!;
  const { customerMetadata } = userSession || {};

  const [numberOfSamples, setNumberOfSamples] = useState<number>(1);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [formula, setFormula] = useState<Maybe<ExtendedFormulaAttributes>>(
    undefined
  );
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);

  useEffect(() => {
    setTotalCost(numberOfSamples * COST_PER_SAMPLE);
  }, [numberOfSamples]);

  useEffect(() => {
    if (rtlFormulaUuid) {
      getFormula({
        urlParams: rtlFormulaUuid,
        handleSuccess: (data) => {
          const { id, attributes, ingredients } = data;
          setFormula({
            id,
            ...attributes,
            ingredients: [...ingredients],
          });
        },
      });
    }
  }, [getFormula, rtlFormulaUuid]);

  useEffect(() => {
    if (!formula) return;

    amplitude.track('RTL Formula Confirmation', {
      formula: formula.name,
    });
  }, [formula]);

  const beginOrder = () => {
    postProjectOrder({
      urlParams: `${projectUuid}/orders`,
      data: {
        order: {
          quantity: numberOfSamples,
        },
      },
      handleSuccess: ({ url }) => {
        window.location.href = url;
      },
    });
  };

  const renderIngredientsList = () => {
    return hasActiveIngredients
      ? renderActiveIngredientsList()
      : renderNonActiveIngredientsList();
  };

  const renderNonActiveIngredientsList = () => {
    return formula?.ingredients.map((ingredient, index) => (
      <span key={ingredient.id}>
        {ingredient.attributes.rawMaterialName}
        {+formula.ingredients.length - 1 === index ? '' : ', '}
      </span>
    ));
  };

  const renderActiveIngredientsList = () => {
    const activeIngredients = formula?.ingredients.filter(
      (ingredient) => ingredient.attributes.active
    );
    const inactiveIngredients = formula?.ingredients
      .filter((ingredient) => !ingredient.attributes.active)
      .flatMap((ingredient) =>
        ingredient.attributes.rawMaterialUsIncis.map((inci) => inci.name)
      )
      .sort();
    // individual ingredients may have incis that are the same, so we need to remove duplicates
    const uniqueInactiveIngredients = Array.from(new Set(inactiveIngredients));

    return (
      <div className="flex flex-col gap-4">
        <table>
          <thead>
            <tr>
              <th className="text-left font-inter">Active Ingredients</th>
              <th></th>
              <th className="text-left">Purpose</th>
            </tr>
          </thead>
          <tbody>
            {activeIngredients!.map((ingredient: any, index) => (
              <tr key={index}>
                <td>{ingredient.attributes.rawMaterialName}</td>
                <td>{ingredient.attributes.amount}%</td>
                {/* this may not be correct since there can potentially be more than one purpose */}
                <td>{ingredient.attributes.usApiDrugPurposes[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <Typography variant="h6" font="inter" weight="semibold">
            Inactive Ingredients
          </Typography>
          <div>
            {uniqueInactiveIngredients.map((ingredient, index) => (
              <span key={index}>
                {ingredient}
                {+uniqueInactiveIngredients.length - 1 === index ? '' : ', '}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const generateReport = async () => {
    const report = hasActiveIngredients
      ? reportTypes.drugs
      : reportTypes.cosmetics;

    try {
      setIsReportGenerating(true);
      const blob = await request({
        resource: `/api/v1/formulas/${rtlFormulaUuid}.${formatType}?reportType=${
          report.type
        }${buildUrlParameters(report.inputs)}&companyName=${
          customerMetadata?.companyName
        }`,
        options: {
          settings: Constants.GET,
        },
      });

      if (blob.size) {
        const blobData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobData;
        link.setAttribute('target', '_blank');
        link.download = `${formula?.name} ${
          report.type
        } ${new Date().toISOString().replace(/T.*/, '')}.${formatType}`;
        link.click();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobData);
        }, 100);
        // showSnackbar('Report generated successfully', 'success');
      } else {
        throw new Error('The report failed to generate.');
      }
    } catch (error) {
      // showSnackbar((error as Error).message, 'error');
      Rollbar.error(error);
    } finally {
      setIsReportGenerating(false);
    }
  };

  const buildUrlParameters = (inputs: ReportInputs) => {
    return Object.values(inputs || {})
      .map((v) => {
        return v.value && v.params ? `&${v.params}=${v.value}` : '';
      })
      .join('');
  };

  const hasActiveIngredients = formula?.ingredients.some(
    (ingredient) => ingredient.attributes.active
  );

  return (
    <div
      data-testid={rtlFormulaUuid}
      className="w-full flex flex-col mt-[60px] sm:-mx[80px]"
    >
      <div>
        <div className="relative flex mb-[110px] mx-6 sm:mx-[140px]">
          <div className="flex flex-col sm:w-[77%] gap-6 bg-white">
            <Typography variant="h2">
              {/* TODO: Connect product type result to actual formik value  */}
              Your <span className="text-blue-60">{formula?.name}</span> is
              Ready to Launch
            </Typography>
            <Typography variant="h4" font="inter" color="secondary">
              Congrats! You’ve chosen a{' '}
              <span className="text-peach-50">Ready to Launch</span> formula,
              which means lower costs and faster speed-to-market.
            </Typography>
            <div>
              <Typography variant="h4" font="inter">
                To receive your sample(s):
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="-mx-[60px] sm:mx-0 bg-blue-95 px-[60px] sm:px-0 pb-[150px]">
        <div className="flex flex-col gap-6 bg-blue-95 sm:px-[140px] sm:pb-[250px] h-full">
          <div className="relative -mt-[80px] flex border border-grey-90">
            <div className="flex p-6 items-center bg-grey-20">
              <Typography variant="h2" color="onDark">
                1
              </Typography>
            </div>
            <div className="flex flex-col gap-6 p-6 bg-white">
              <Typography variant="h3" weight="black">
                Approve your ingredients list
              </Typography>
              <div className="flex flex-col gap-4 p-6 bg-blue-99 border border-grey-90">
                <Typography variant="h6" font="mono" weight="semibold">
                  INGREDIENTS LIST:
                </Typography>
                <Typography font="inter" variant="h5" color="secondary">
                  {renderIngredientsList()}
                </Typography>
                <div className="flex flex-col gap-4 sm:flex-row justify-between">
                  <Button
                    leadingIcon={<ArrowDownTrayIcon />}
                    type="secondary"
                    text="Download IL"
                    action={generateReport}
                    disabled={isReportGenerating}
                    width={isMobile ? 'w-full' : ''}
                    size={isMobile ? 'medium' : 'large'}
                  />
                  <Button
                    leadingIcon={isApproved ? <CheckIcon /> : undefined}
                    iconSize="20px"
                    type={isApproved ? 'accepted' : 'primary'}
                    text={isApproved ? 'Approved' : 'Approve'}
                    action={() => setIsApproved(!isApproved)}
                    width={isMobile ? 'w-full' : ''}
                    size={isMobile ? 'medium' : 'large'}
                  />
                </div>
              </div>
              <div>
                <Typography variant="h4" font="inter">
                  You will receive sample(s) of this base formula. Then, you can
                  discuss modifications like color or fragrance with your
                  account representative.
                </Typography>
              </div>
              <div>
                <Typography variant="h5" font="inter" color="secondary">
                  Not quite what you were looking for? No problem.{' '}
                  <a href="help@primematterlabs.com" className="underline">
                    Contact us
                  </a>
                  <span> </span>
                  about making adjustments, or{' '}
                  <a
                    href={`${window.origin}/custom-brief`}
                    className="underline"
                  >
                    restart this form
                  </a>
                  .
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex border border-grey-90">
            <div className="flex p-6 items-center bg-grey-20">
              <Typography variant="h2" color="onDark">
                2
              </Typography>
            </div>
            <div className="flex flex-col gap-6 sm:gap-0 sm:flex-row w-full justify-between sm:items-center p-6 pr-12 bg-white">
              <div className="flex flex-col gap-3 sm:p-6">
                <Typography variant="h3" weight="black">
                  Order your samples
                </Typography>
                <Typography variant="h4" font="inter">
                  How many samples would you like?
                </Typography>
                <div className="flex items-center gap-3">
                  <div className="w-fit-content">
                    <IncrementerDecrementer
                      setValue={setNumberOfSamples}
                      value={numberOfSamples}
                    />
                  </div>
                  <Typography variant="h4" font="inter">
                    Total: <span className="font-bold">${totalCost}</span>
                  </Typography>
                </div>
              </div>
              <div>
                {projectUuid && (
                  <Button
                    type="primary"
                    text="Check out"
                    trailingIcon={<ArrowRightIcon />}
                    onClick={beginOrder}
                    disabled={!isApproved}
                    width={isMobile ? 'w-full' : ''}
                    size={isMobile ? 'large' : 'medium'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
