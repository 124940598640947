// Libraries
import React, { useCallback, useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/16/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import _ from 'lodash';
// Components
import { TextField } from '.';
import { Menu } from './select.component';
// Utils
import { searchResourceFunctionType } from 'api';

interface AutocompleteProps {
  clearField?: () => void;
  label?: string;
  multiSelectMode?: boolean;
  required?: boolean;
  responseParser: (response: any) => Option[];
  search: searchResourceFunctionType;
  selectedOptions?: Option[];
  urlParams?: string;
  [x: string]: any;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  clearField,
  label,
  multiSelectMode,
  required,
  responseParser,
  search,
  selectedOptions,
  urlParams,
  ...rest
}) => {
  if (multiSelectMode && !selectedOptions) {
    throw new Error('selectedOptions is required when multiSelectMode is true');
  }

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce(async (query) => {
      if (!query) return;
      setIsLoading(true);
      await search({
        query: encodeURIComponent(query),
        urlParams: urlParams || '',
        handleSuccess: (response) => {
          setOptions(responseParser(response));
        },
        handleFinally: () => setIsLoading(false),
      });
    }, 300),
    [search]
  );

  useEffect(() => {
    debouncedSearch(inputValue);
    return () => debouncedSearch.cancel();
  }, [inputValue, debouncedSearch, search]);

  // Handles for typing into the input
  const handleAutocompleteChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(e.target.value);
    setIsMenuOpen(true);
  };

  // Handles clearing the input when the user presses backspace
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (multiSelectMode) return;

    if (rest.value && e.key === 'Backspace') {
      clearField && clearField();
      setInputValue('');
    }
  };

  // Handles selecting an option from the menu
  const handleOptionSelection = (option: Option) => {
    rest.onChange(
      multiSelectMode ? _.uniq([...(selectedOptions as []), option]) : option
    );
    multiSelectMode ? setInputValue('') : setInputValue(option.label);
    setIsMenuOpen(false);
  };

  const handleClear = () => {
    clearField && clearField();
    setInputValue('');
  };

  return (
    <div className="relative">
      <TextField
        required={required}
        label={label}
        value={
          multiSelectMode
            ? inputValue
            : // The value prop is passed down by Formik as an object with a shape of { label: string, value: string }
              // So in order to find the display value, we need to find the option with the same value
              // Hence value.value
              options.find(
                (option) =>
                  option.value === rest.value?.value ||
                  option.value === rest.value
              )?.label ||
              rest.value?.label ||
              inputValue
        }
        onChange={handleAutocompleteChange}
        onKeyDown={handleOnKeyDown}
        trailingIcon={
          rest.value?.value && !multiSelectMode ? (
            <XCircleIcon
              className="cursor-pointer"
              onClick={() => handleClear()}
              height="20"
              width="20"
            />
          ) : (
            <MagnifyingGlassIcon
              className="text-grey-50"
              height="20"
              width="20"
            />
          )
        }
        {..._.omit(rest, ['onChange', 'value'])}
      />
      {isMenuOpen && (
        <Menu
          inputHasLabel={Boolean(label)}
          inputHasHelperText={Boolean(rest.helperText)}
          isLoading={isLoading}
          name={rest.name}
          onChange={handleOptionSelection}
          options={options}
          onClose={() => setIsMenuOpen(false)}
        />
      )}
    </div>
  );
};
