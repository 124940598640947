// Libraries
import React from 'react';
import { XMarkIcon } from '@heroicons/react/16/solid';

interface ChipProps {
  additionalClasses?: string;
  bgColor?: string;
  borderColor?: string;
  closeIconColor?: string;
  label: string;
  leadingIcon?: React.ReactNode;
  noHover?: boolean;
  onClick?: () => void;
  onCloseIconClick?: (event: React.MouseEvent) => void;
  selected?: boolean;
  selectedBgColor?: string;
  selectedTextColor?: string;
  size?: 'small' | 'large';
  textColor?: string;
  trailingIcon?: React.ReactNode;
  value: string;
}

export const Chip: React.FC<ChipProps> = ({
  additionalClasses = '',
  bgColor = 'bg-white',
  borderColor = 'border-grey-80',
  closeIconColor = 'text-grey-50',
  label,
  leadingIcon,
  noHover,
  onClick,
  onCloseIconClick,
  selected,
  selectedBgColor,
  selectedTextColor,
  size = 'large',
  textColor = 'text-grey-20',
  trailingIcon,
  value,
}) => {
  return (
    <div
      key={`${label}-${value}`}
      className={`${
        selected ? (selectedBgColor ? selectedBgColor : 'bg-grey-90') : bgColor
      } ${selected ? 'border-royal-50' : borderColor} ${
        selected ? selectedTextColor : textColor
      } ${additionalClasses} whitespace-nowrap  ${
        !noHover && 'cursor-pointer'
      } ${
        size === 'large' ? 'py-[6px] h-7' : 'py-[2px] h-[22px]'
      } flex gap-[6px] px-2 items-center border rounded-full font-inter text-xs`}
      onClick={onClick && (() => onClick())}
    >
      {leadingIcon}
      <span>{label}</span>
      {trailingIcon}
      {onCloseIconClick && selected && (
        <XMarkIcon
          onClick={onCloseIconClick}
          className={`${closeIconColor} cursor-pointer h-4 w-4`}
        />
      )}
    </div>
  );
};
